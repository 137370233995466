<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialogSection"
        :retain-focus="false"
        persistent
        max-width="1000px"
        scrollable
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span v-if="type_form_section == 'add'" class="headline"
                >Thêm Section</span
              >
              <span v-if="type_form_section == 'update'" class="headline"
                >Cập nhật Section</span
              >
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSection = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="">
                  <v-text-field
                    :readonly="!canInputTitleSection()"
                    label="Tiêu đề"
                    v-model="inputTitleSection"
                    required
                  ></v-text-field>
                </v-col>
                <template v-if="skills && skills.length">
                  <v-col cols="12" class="" v-if="allowedToUseConfigPart()">
                    <label style="font-size: 16px">Part</label>
                    <v-select
                      :readonly="!canInputPartSection()"
                      :items="filterPartConfigByCategoryId()"
                      item-text="part_name"
                      item-value="id"
                      v-model="part_input"
                      dense outlined
                    ></v-select>
                  </v-col>
                </template>
                <v-col cols="12" class="pt-0">
                  <label style="font-size: 16px">Mô tả / Nội dung</label>
                  <Editor v-model="descriptionQuestionInput"></Editor>
                </v-col>
                <v-col cols="12" v-if="checkCanUseInputLimitTime()">
                  <label style="font-size: 16px">Thời gian làm bài của part 1 (phút : giây):</label>
                  <date-picker
                      class="ml-2"
                      v-model="limit_time"
                      type="time"
                      format="mm:ss"
                      value-type="format"
                      placeholder="mm:ss"
                  ></date-picker>
                </v-col>
                <v-col cols="12">
                  <label style="font-size: 16px">Summary</label>
                  <Editor v-model="summary_input"></Editor>
                </v-col>
                <v-col cols="12">
                  <label style="font-size: 16px">Thành quả đạt được</label>
                  <Editor v-model="achievement_input"></Editor>
                </v-col>
                <v-col cols="12" class="d-flex pb-0">
                  <label style="font-size: 16px"
                    >Cho học sinh đánh giá section:</label
                  >
                  <v-radio-group
                    v-model="required_rating_input"
                    row
                    class="mt-0 pt-0"
                  >
                    <v-radio label="Có" :value="1"></v-radio>
                    <v-radio label="Không" :value="0"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12">
                  <label style="font-size: 16px">Gắn Tag</label>
                  <multiselect
                    v-model="tag_selected"
                    tag-placeholder="Tạo tag này"
                    placeholder="Tìm kiếm hoặc tạo tag"
                    label="name"
                    track-by="id"
                    :options="tags"
                    :multiple="true"
                    :taggable="true"
                    @tag="addTag"
                  ></multiselect>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darken-1" text @click="dialogSection = false"
              >Đóng</v-btn
            >
            <v-btn
              color="blue" class="font-weight-bold" outlined text v-if="type_form_section == 'add'"
              @click="btnAddSection"
              >Thêm
            </v-btn>
            <v-btn
              color="blue" class="font-weight-bold" outlined text v-if="type_form_section == 'update'"
              @click="btnEditSection"
              >Cập nhật
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ApiService from "@/service/api.service";
import configPart from "./Support/Section/configPart";
import productCategorySupport from "./Support/Test/productCategorySupport";
import constantTestBuilder from "../mixins/Support/constantTestBuilder";

export default {
  name: "SectionModal",
  components: {
    Editor: () => import("@/components/ckeditor5/Editor"),
  },
  mixins: [
      configPart,
      productCategorySupport,
      constantTestBuilder,
  ],
  data() {
    return {
      inputTitleSection: null,
      descriptionQuestionInput: '',
      achievement_input: "",
      offsetSortSection: 0,
      tag_selected: [],
      required_rating_input: 1,
      part_input: null,
      directions_audio_input: null,
      exercise_limit: null,
      question_limit: null,
      limit_time: null,
      skillCurrent: null,
      summary_input: "",
    };
  },
  computed: {
    ...mapState({
      tags: (state) => state.informationTestBuilderStore.tags,
      type_form_section: (state) =>
        state.contentTestBuilderStore.type_form_section,
      test_id: (state) => state.informationTestBuilderStore.test_id,
      skill_id: (state) => state.contentTestBuilderStore.skill_id,
      section_id: (state) => state.contentTestBuilderStore.section_id,
      indexSkill: (state) => state.contentTestBuilderStore.indexSkill,
      indexSection: (state) => state.contentTestBuilderStore.indexSection,
      partConfig: (state) => state.sectionPartConfig.partConfig,
      product_category_id: (state) => state.informationTestBuilderStore.product_category_id,
      type_expand: (state) => state.informationTestBuilderStore.type_expand,
    }),
    ...mapGetters({
      skills: "contentTestBuilderStore/skills",
    }),
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
    dialogSection: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogSection;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setDialogSection", value);
      },
    },
    tabSection: {
      get() {
        return this.$store.state.contentTestBuilderStore.tabSection;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setTabSection", value);
      },
    },
  },
  watch: {
    dialogSection: function (val) {
      if (val) {
        if (!this.canOpenDialogSection()) {
          this.$nextTick(() => {
            this.dialogSection = false;
          })
        }
        this.setDataInput(this.type_form_section);
      }
    },
    part_input(val) {
      this.setInputSectionByPartId(val);
    }
  },
  created() {},
  methods: {
    canOpenDialogSection() {
      let flat = true;
      if(this.type_form_section == 'add') {
        if (this.product_category_id === this.product_category_constant.IELTS) {
          if (this.skills[this.indexSkill]?.skill_type === 'Speaking' && this.skills[this.indexSkill]?.vsr === 1) {
            this.errorMess('Bạn không thể thêm section cho VSR IELTS');
            flat = false;
          }
        }

        if (this.product_category_id === this.product_category_constant.HSK) {
          if (this.skills[this.indexSkill]?.skill_type === 'Speaking' && this.skills[this.indexSkill]?.vsr === 1) {
            this.errorMess('Không được thêm section trong test VSR HSKK');
            flat = false;
          }
        }

      }

      return flat;
    },
    setDataInput(type) {
      this.skillCurrent = this.skills[this.indexSkill].skill_type;
      if (type == "add") {
        this.inputTitleSection = null;
        this.descriptionQuestionInput = "";
        this.achievement_input = "";
        this.offsetSortSection = this.skills[this.indexSkill].sections.length + 1;
        this.required_rating_input = 1;
        this.part_input = null;
        this.summary_input = "";
        if (this.skillCurrent === 'Writing' && this.skills[this.indexSkill].vwr === 1) {
          this.limit_time = "08:00";
        }
        this.limit_time = null;
      } else {
        let data = this.skills[this.indexSkill].sections[this.indexSection];
        data = JSON.parse(JSON.stringify(data));
        this.inputTitleSection = data.section_title;
        this.descriptionQuestionInput = data.section_description ? data.section_description : '';
        this.achievement_input = data.achievements == null ? "" : data.achievements;
        this.summary_input = data.summary ? data.summary : "";
        this.tag_selected = data.tags;
        this.required_rating_input = data.required_rating ? data.required_rating : 1;
        this.part_input = data.partConfig ? data.partConfig.id : null; // warning : change section by section_config_part
        this.exercise_limit = data.exercise_limit;
        this.question_limit = data.question_limit;
        this.limit_time = data.limit_time ? this.$options.filters.convertSecondToMS(data.limit_time) : null;
        this.summary_input = data.summary ?? "";
      }
    },
    validateFormSection() {
      let flat = true;
      if (this.inputTitleSection == null || this.inputTitleSection == "") {
        this.errorMess("Hãy nhập tiêu đề cho section");
        flat = false;
      }
      if (this.skills[this.indexSkill].skill_type === 'Speaking' ) {
        if (
            this.skills[this.indexSkill].vsr === 1 ||
            (this.product_category_id === this.product_category_const.PREPTALK_ENGLISH && this.type_expand === this.test_type_expand_constant.LESSON)
        ) {
          if (!this.part_input) {
            this.errorMess("Hãy chọn part cho section !");
            flat = false;
          }
          let dataPartIdSectionExist = this.skills[this.indexSkill].sections.map((section) => section.partConfig?.id);
          if (this.type_form_section === 'update') {
            dataPartIdSectionExist = dataPartIdSectionExist.filter((partId) => partId != this.skills[this.indexSkill].sections[this.indexSection].partConfig?.id);
          }
          if (dataPartIdSectionExist.includes(this.part_input)) {
            this.errorMess("Bạn đã nhập part này trong bài test. Vui lòng kiểm tra lại !");
            flat = false;
          }
        }
      }
      if (this.skillCurrent === 'Writing' && this.skills[this.indexSkill].vwr === 1) {
        if (this.part_input) {
          let partConfig = this.getPartConfigById(this.part_input);
          if (this.product_category_id === this.product_category_const.TOEIC) {
            if (
                partConfig.module === this.module_section_part.VWR &&
                partConfig.offset === this.offset_section_part.PART_1 &&
                !this.limit_time
            ) {
              this.errorMess("Hãy nhập thời gian làm bài !");
              flat = false;
            }
          }
        } else {
          this.errorMess("Hãy chọn part cho section !");
          flat = false;
        }
      }
      return flat;
    },
    async btnAddSection() {
      let validate = this.validateFormSection();
      if (!validate) {
        return;
      }
      let vm = this;
      vm.is_call_api = true;
      let data = {
        skill_id: this.skill_id,
        test_id: this.test_id,
        section_title: this.inputTitleSection,
        section_description: this.descriptionQuestionInput,
        is_element_pinned: false,
        element_data: null,
        element_id: null,
        element_type: null,
        element_timestamp: [],
        total_question: 0,
        offset: this.offsetSortSection,
        exercises: [],
        tags: [],
        achievements: this.achievement_input,
        required_rating: this.required_rating_input,
        part: this.part_input,
        directions_audio: this.directions_audio_input,
        exercise_limit: this.exercise_limit,
        question_limit: this.question_limit,
        summary: this.summary_input,
      };
      if (this.tag_selected) {
        data.tag = this.tag_selected;
        data.tag_ids = this.tag_selected.map((e) => {
          return e.id;
        });
      }
      if (this.limit_time) {
        data.limit_time = this.$options.filters.convertMSToSecond(this.limit_time);
      }
      if (this.part_input) {
        data.partConfig = this.getPartConfigById(this.part_input);
      }
      await ApiService.post("prep-app/test/draft/section", data)
        .then(function (res) {
          if (res.status == 200) {
            let dataCheck = vm.skills[vm.indexSkill].sections;
            if (dataCheck == undefined || dataCheck == null) {
              vm.skills[vm.indexSkill].sections = [];
            }
            data.id = res.data.id_section;
            data.id_section_test_mapping = res.data.id_section_test_mapping;
            let indexSection = vm.skills[vm.indexSkill].sections.push(data) - 1;
            if (indexSection == 0) {
              vm.skills[vm.indexSkill].sections[indexSection].offset_question = 0;
            } else {
              let offset = 0;
              for (let x = 0; x < indexSection; x++) {
                for (let y = 0; y < vm.skills[vm.indexSkill].sections[x].exercises.length; y++) {
                  offset += vm.skills[vm.indexSkill].sections[x].exercises[y].total_question;
                }
              }
              vm.skills[vm.indexSkill].sections[indexSection].offset_question = offset;
            }
            vm.skills[vm.indexSkill].currentTabSection = vm.skills[vm.indexSkill].sections.length - 1;
            vm.skills[vm.indexSkill].totalSection = vm.skills[vm.indexSkill].sections.length;
            vm.$set(vm.skills);
            //vm.tabSection = vm.skills[vm.indexSkill].sections.length - 1;
            vm.is_call_api = false;
          }
        })
        .catch(function (error) {
          if (error) {
            vm.is_call_api = false;
            if ([422].includes(error.response.status) ) {
              vm.errorMess(error.response.data.error.message);
            } else {
              vm.errorMess('Có lỗi xảy ra. Vui lòng liên hệ bộ phận kỹ thuật hỗ trợ');
            }
          }
        });
      this.dialogSection = false;
    },
    async btnEditSection() {
      let validate = this.validateFormSection();
      if (!validate) {
        return;
      }
      let vm = this;
      vm.is_call_api = true;
      let data = {
        id: this.section_id,
        skill_id: this.skill_id,
        test_id: this.test_id,
        section_title: this.inputTitleSection,
        section_description: this.descriptionQuestionInput,
        tags: [],
        achievements: this.achievement_input,
        required_rating: this.required_rating_input,
        part: this.part_input,
        directions_audio: this.directions_audio_input,
        exercise_limit: this.exercise_limit,
        question_limit: this.question_limit,
        summary: this.summary_input,
      };
      if (this.tag_selected) {
        data.tag = this.tag_selected;
        data.tag_ids = this.tag_selected.map((e) => {
          return e.id;
        });
      }
      if (this.part_input) {
        data.partConfig = this.getPartConfigById(this.part_input);
      }
      await ApiService.put("prep-app/test/draft/section/" + vm.section_id, data)
        .then(function (res) {
          if (res.status == 200) {
            vm.skills[vm.indexSkill].sections[vm.indexSection] = data;
            vm.is_call_api = false;
          }
        })
        .catch(function (error) {
          console.log(error);
          if (error) {
            vm.is_call_api = false;
            if ([422].includes(error.response.status) ) {
              vm.errorMess(error.response.data.error.message);
            } else {
              vm.errorMess('Có lỗi xảy ra. Vui lòng liên hệ bộ phận kỹ thuật hỗ trợ');
            }
          }
        });
      this.dialogSection = false;
    },
    addTag(newTag) {
      console.log(newTag);
      // let tag = {
      //   name: newTag,
      // }
      // this.value.push(tag)
    },
    setInputSectionByPartId(partId = 0) {
      let checkPartExsit = this.partConfig.filter((part) => part.id == partId);
      if (checkPartExsit.length > 0) {
        let part = checkPartExsit[0];
        this.inputTitleSection = part.title ? part.title : this.inputTitleSection;
        this.descriptionQuestionInput = part.direction ? part.direction : this.descriptionQuestionInput;
        this.directions_audio_input = part.directions_audio;
        this.exercise_limit = part.exercise_limit ? part.exercise_limit : this.exercise_limit;
        this.question_limit = part.question_limit ? part.question_limit : this.question_limit;
      }
    },
    checkCanUseInputLimitTime() {
      let flat = false;

      if (this.product_category_id === this.product_category_const.TOEIC) {
        if (this.skillCurrent === 'Writing' &&
            this.skills[this.indexSkill]?.vwr === 1 &&
            this.skills[this.indexSkill]?.sections[this.indexSection].partConfig.offset === 1 &&
            this.skills[this.indexSkill]?.sections[this.indexSection].partConfig.module === this.module_section_part.VWR) {
          flat = true;
        }
      }

      return flat;
    },
    getPartConfigById(partId = 0){
      let partConfig = this.partConfig.filter((part) => part.id == partId);
      return partConfig[0];
    },
    filterPartConfigByCategoryId() {
      let partConfigCp = JSON.parse(JSON.stringify(this.partConfig));
      if (this.product_category_id) {
        partConfigCp = partConfigCp.filter((config) => {
          return config.product_category_id === this.product_category_id;
        });
      }
      if (this.skills[this.indexSkill]?.vsr == 1) {
        partConfigCp = partConfigCp.filter((config) => {
          return config.module === this.module_section_part.VSR;
        });
      }
      if (this.skills[this.indexSkill]?.vwr == 1) {
        partConfigCp = partConfigCp.filter((config) => {
          return config.module === this.module_section_part.VWR;
        });
      }
      return partConfigCp;
    },
    allowedToUseConfigPart() {
      let flat = false;
      if (this.product_category_id === this.product_category_constant.TOEIC) {
        if (this.skills[this.indexSkill]?.vsr === 1) {
          if (this.skills[this.indexSkill]?.skill_type === 'Speaking') {
            flat = true;
          }
        }
      }

      if (this.product_category_id === this.product_category_constant.PREPTALK_ENGLISH) {
        if (this.skills[this.indexSkill]?.skill_type === 'Speaking') {
          if (this.type_expand === this.test_type_expand_constant.LESSON) {
            flat = true;
          }
        }
      }

      if (this.product_category_id === this.product_category_constant.IELTS) {
        if (this.skills[this.indexSkill]?.skill_type === 'Writing' && this.skills[this.indexSkill]?.vwr === 1) {
          flat = true;
        }
        if (this.skills[this.indexSkill]?.skill_type === 'Speaking' && this.skills[this.indexSkill]?.vsr === 1) {
          flat = true;
        }
      }

      if (this.product_category_id === this.product_category_constant.HSK) {
        if (this.skills[this.indexSkill]?.skill_type === 'Speaking' && this.skills[this.indexSkill]?.vsr === 1) {
          flat = true;
        }
      }

      return flat;
    },
    errorMess(msg = '') {
      this.$toasted.error(msg, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
    canInputPartSection() {
      let flat = true;
      if (this.product_category_id === this.product_category_constant.PREPTALK_ENGLISH) {
        if (this.type_expand === this.test_type_expand_constant.LESSON) {
          flat = false;
        }
      }
      if (this.product_category_id === this.product_category_constant.IELTS) {
        if (this.skills[this.indexSkill]?.skill_type === 'Speaking' && this.skills[this.indexSkill]?.vsr === 1) {
          flat = false;
        }
      }
      if (this.product_category_id === this.product_category_constant.HSK) {
        if (this.skills[this.indexSkill]?.skill_type === 'Speaking' && this.skills[this.indexSkill]?.vsr === 1) {
          flat = false;
        }
      }
      return flat;
    },
    canInputTitleSection() {
      let flat = true;
      if (this.product_category_id === this.product_category_constant.PREPTALK_ENGLISH) {
        if (this.type_expand === this.test_type_expand_constant.LESSON) {
          flat = false;
        }
      }
      return flat;
    },
  },
};
</script>

<style></style>
